//imports
import React, { Component} from "react";
import "./style.css"
import API from "../../utils/API";
import { useState, useContext,useCallback, useEffect, useRef} from "react";
import { Input, Container, Row, Col, Card, CardImg, CardText, CardBody, CardTitle, Button, CardFooter, ModalBody, Modal, Label} from 'reactstrap';
import './style.css';
import io from 'socket.io-client';
import {useParams} from 'react-router-dom';
import countdownAudio from './assets/sounds/countdown.wav'; // Adjust the path to your audio file
import PlayerCard from "../../components/playerCard";
import { beepNotif, copperDing, correctAnswer, countdown, gameStartCountdown, wrongBuzzer } from './jeopardyAudio';
import pointSound from './assets/sounds/plus-points.mp3';
import WaitingRoom from "../../components/WaitingRoom/jeopardyWaitingRoom";
import QRcodeImg from '../../components/QRcodeImg'
//state 

const Jeopardy = (props) => {
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [buzzerChecked, setBuzzerChecked] = useState(props.buzzerChecked);
    const [sounds] = useState([beepNotif, copperDing, correctAnswer, countdown, gameStartCountdown, wrongBuzzer])
    const [teams, setTeams] = useState({});
    const [modalExpand, setModalExpand]= useState(false);
    const [modalCss, setModalCss]= useState();
    const [modalToggle, setModalToggle] = useState(false);
    const modalToggleRef = useRef(modalToggle); // Create a ref to track modalToggle
    const [points, setPoints] = useState({
        1:"100",
        2:"200",
        3:"300",
        4:"400",
        5:"500",
        6:"600",
        7:"700", 
        8:"800"
    });
    const [questionSetData, setQuestionSetData] = useState(props.questionset);
    const [totalColumns, setTotalColumns ] = useState(6);
    const [totalRows, setTotalRows ] = useState(6);
    const [questions,setQuestions] = useState({});
    const [categoryNames, setCategoryNames] = useState({});
    const [roomId, setRoomId] = useState(null);
    const [teamBuzzed, setTeamBuzzed] = useState(null);
    const togglemodalToggle = () => {
        audio.pause();
        audio.currentTime = 0;          
        setModalToggle(!modalToggle)
        setTeamBuzzed(null)
    };
    const [currentModal, setCurrentModal] =useState();
    const [modalOpened, setModalOpened] = useState({});
    const [showAnswer, setShowAnswer] = useState(false);
    useEffect(() => {
      modalToggleRef.current = modalToggle; // Sync modalToggle with the ref whenever it changes
    }, [modalToggle]);
  
    useEffect(()=>{ 
        let jeopardyData = questionSetData;
        if(jeopardyData.Jeopardy){
          API.addPlayCount(props.questionSet.id)
            setTotalColumns(jeopardyData.Jeopardy.columns);
            setTotalRows(jeopardyData.Jeopardy.rows);
            let categoryNamesTemp = {...categoryNames}
            for(let i = 0; i <= jeopardyData.Jeopardy.columns; i++){
                if(jeopardyData.Jeopardy[i]){
                    categoryNamesTemp[i]= jeopardyData.Jeopardy[i];
                }
            }
            setCategoryNames(categoryNamesTemp);
        }
        if(jeopardyData.JeopardyPoint){
            setPoints(jeopardyData.JeopardyPoint);
        }
        if(jeopardyData.JeopardyQuestions){
            let questionsTemp = {...questions};
            for(let i =0; i< jeopardyData.JeopardyQuestions.length;i++){
                let position = jeopardyData.JeopardyQuestions[i].position;
                if(position){
                    questionsTemp[position]={};
                    questionsTemp[position].question=jeopardyData.JeopardyQuestions[i].question;
                    questionsTemp[position].answer=jeopardyData.JeopardyQuestions[i].answer;
                    questionsTemp[position].image=jeopardyData.JeopardyQuestions[i].image;
                    questionsTemp[position].id=jeopardyData.JeopardyQuestions[i].id;
                }
            }
            setQuestions(questionsTemp)
        }

    },[]);

    const timeoutRef = useRef(null);
    const [showContent, setShowContent] = useState(false);
    const openModal = (e,modalID) =>{
        setShowContent(false);
        setShowAnswer(false);
        setTimeLeft(30);
        setModalExpand(false);
        setCurrentModal(modalID);
        togglemodalToggle();
        setModalCss({top: e.clientY+"px", left: e.clientX+"px", });
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            setModalExpand(true);
          }, 50);    
        if(questions[modalID]){
          startTimer(modalID);
        }
        setTimeout(() => {
          setShowContent(true);
      }, 300); // Adjust this timeout to match the CSS transition duration

        let modalOpenedTemp = {...modalOpened};
        modalOpenedTemp[modalID] = true;
        setModalOpened(modalOpenedTemp);
    };
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~TIMER FOR JEOPARDY GAME  && COUNTDOWN START~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        //set a timer for the questions
        const [timeLeft, setTimeLeft] = useState(30);
        const [timerStarted, setTimerStarted] = useState(false);
    
        useEffect(() => {
            if(timeLeft===10){
              audio.play();
            }
            if(modalToggle==false){
                setTimeLeft(30);
                setTimerStarted(false);
                return;
            }
            if (!timerStarted) return;
            
            if (timeLeft <= 0) {
             // Close the card when the timer reaches 0
                audio.pause();
                audio.currentTime = 0;
       
              return;
            }
        
            const timer = setTimeout(() => {
              setTimeLeft(timeLeft - 1);
            }, 1000);
        
            return () => clearTimeout(timer);
          }, [timeLeft, timerStarted]);
        
        const startTimer = (modalID) => {
            if(modalOpened[modalID]){
              setTimeLeft(null);
            } else{
              setTimerStarted(true);
            }
        };
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~TIMER FOR JEOPARDY GAME & COUNTDOWN END ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE FULL SCREEN TOGGLE START ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            const elementRef = useRef(null);
            const [fullscreen, setFullScreen] = useState(false)
            const handleFullscreen = () => {
              if (!document.fullscreenElement) {
                // Enter fullscreen
                if (elementRef.current.requestFullscreen) {
                  elementRef.current.requestFullscreen();
                } else if (elementRef.current.mozRequestFullScreen) { /* Firefox */
                  elementRef.current.mozRequestFullScreen();
                } else if (elementRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                  elementRef.current.webkitRequestFullscreen();
                } else if (elementRef.current.msRequestFullscreen) { /* IE/Edge */
                  elementRef.current.msRequestFullscreen();
                }
                setFullScreen(true)
              } else {
                // Exit fullscreen
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                  document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                  document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                  document.msExitFullscreen();
                }
                setFullScreen(false)

              }
            };
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE FULL SCREEN TOGGLE END~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE AUDIO TOGGLE START~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            const [audio] = useState(new Audio(countdownAudio));
            const [isMuted, setIsMuted] = useState(false);
            const [volume, setVolume] = useState(0.5); // Volume ranges from 0.0 to 1.0         
            const playPointsSound =()=>{
              const PointAudio = new Audio(pointSound);
              PointAudio.volume = volume;
              if(isMuted){
                PointAudio.muted=true
              }
              PointAudio.play();
            } 
            useEffect(()=>{
              audio.volume = volume;
              for(let i=0; i<sounds.length; i++){
                sounds[i].setVolume(volume);
                if(isMuted){
                  sounds[i].mute();
                }else{
                  sounds[i].unmute();
                }
              }

            },[])
            const changeVolume = (event) => {
                const volumeValue = event.target.value;
                for(let i=0; i<sounds.length; i++){
                  sounds[i].setVolume(event.target.value);
                }
                audio.volume = volumeValue;
                setVolume(volumeValue);
                if (volumeValue == 0) {
                  setIsMuted(true);
                } else {
                  setIsMuted(false);
                }
              };
              const toggleMute = () => {
                for(let i=0; i<sounds.length; i++){
                  if(!isMuted){
                    sounds[i].mute();
                  }else{
                    sounds[i].unmute();
                  }
                }

                audio.muted = !isMuted;
                setIsMuted(!isMuted);
              };
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE AUDIO TOGGLE END~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            //~~~~~~~~~~~~~~~~~HANDLE TEAMS points START~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const [correctButtonDisabled, setCorrectButtonDisabled] = useState(false);
            const [incorrectButtonDisabled, setIncorrectButtonDisabled] = useState(false);

              const addPoints=(key)=>{
                let teamsTemp={...teams}
                teamsTemp[key].points = teamsTemp[key].points+100;
                setTeams(teamsTemp)
                playPointsSound();  
              }
              const minusPoints=key=>{
                let teamsTemp={...teams}
                teamsTemp[key].points = teamsTemp[key].points-100;
                setTeams(teamsTemp)
                playPointsSound();  

              }
              const onCorrectClick=(key)=>{
                if(!correctButtonDisabled){
                  let teamsTemp={...teams}
                  teamsTemp[key].points = teamsTemp[key].points+Number(points[currentModal[1]]);
                  setTeams(teamsTemp)
                  setTimerStarted(false);
                  correctAnswer.play();  
                  setCorrectButtonDisabled(true);
                  setTimeout(() => {
                    setCorrectButtonDisabled(false);
                  }, 2500);
                }   
              };
              const onIncorrectClick=(key)=>{
                if(!incorrectButtonDisabled){
                  let teamsTemp={...teams}
                  teamsTemp[key].points = teamsTemp[key].points-Number(points[currentModal[1]]);
                  setTeams(teamsTemp)
                  wrongBuzzer.play();  
                  setIncorrectButtonDisabled(true);
                  setTimeout(() => {
                    setIncorrectButtonDisabled(false);
                  }, 2500);
                }
              };

            //~~~~~~~~~~~~~~~~~HANDLE TEAMS points END~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE SOCKET IO CLIENT~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
              // Handling roomCreated event to receive the created roomId
            const [socket, setSocket] = useState(null);
            useEffect(() => {
              // const newSocket = io('/jeopardy');
              const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}/jeopardy`, {
                withCredentials: true
            });
          
              setSocket(newSocket);
            }, []);

            useEffect(() => {
              if (!socket) return;
              let teamTemp=props.teams;
              setTeams(teamTemp);
             // Attach handler function
              socket.emit('createRoom', teamTemp);

              socket.on('roomCreated', handleRoomCreated); // Attach handler function
              function handleRoomCreated(roomId) {
                setRoomId(roomId);
                setQrCodeUrl('/buzzer/' + roomId);

                  // Base URL of the GoQR.me API
                  const baseUrl = 'https://api.qrserver.com/v1/create-qr-code/';
                  // Parameters for the API (size and data to encode)
                  const params = `?size=300x300&data=${encodeURIComponent(roomId)}`;
                  // Construct the full URL
                  let qrcode = `${baseUrl}${params}`;
      
                // socket.emit('joinRoom', roomId);
                // socket.emit('selectTeam', 0, roomId); // Send selected team to server

                // Once room ID is set, turn off the listener
                socket.off('roomCreated', handleRoomCreated);
              }
              socket.on('teamJoined',teams=>{
                setTeams(teams);
              })
              socket.on('buzzed', ({ userId, team }) => {
                if (modalToggleRef.current) { // Use ref to check the latest modalToggle value
                    // setTeamBuzzed(team);
                    setTeamBuzzed(prevTeamBuzzed => {
                      if (prevTeamBuzzed === null) {
                          beepNotif.play();
                          return team;
                      }
                      return prevTeamBuzzed; // no update if already set
                    });
                  }
                // Update UI or perform other actions based on buzzer press
              });
              // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  
              return () => {
                socket.off('teamJoined');
                socket.off('roomCreated');
                socket.off('buzzed'); // Clean up event listener on unmount (optional)
                socket.emit('closeRoom', roomId)
                socket.disconnect();
              };
            },[socket]);
            const onGameStart=()=>{
              setBuzzerChecked(false);
            }
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE SOCKET IO END~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            const onShowAnswer = ()=>{
              setShowAnswer(!showAnswer);
              
            }
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  

        return (
          <div>
            
          {buzzerChecked ? 
          <WaitingRoom
            roomId={roomId}
            qrCodeUrl={qrCodeUrl}
            onGameStart={onGameStart}
            teams={teams}
          />
            :
            <div ref={elementRef} className={"game-background" }>
                {/* MODAL */}
                {modalToggle ?           
                 <div className="jeopardy-modal-container" onClick={e=>{togglemodalToggle()}}>
                    <div className={"jeopardy-modal text-center " + (modalExpand? "jeopardy-modal-expand":"") }style={modalCss} onClick={e=>e.stopPropagation()}>
                    {questions[currentModal]? 
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">

                          <div className="j-timer-container titan-one-regular">
                            <h1>{timeLeft}</h1>
                          </div>

                            {showAnswer?
                              <div className="m-3 nunito">
                                <h1>{questions[currentModal].answer}</h1>
                              </div>
                            :
                              <div>
                                {questions[currentModal].image && <img className="preview-image" src={questions[currentModal].image} alt="Image description" />}
                                <div className="m-3 nunito">
                                  <h1>{questions[currentModal].question}</h1>
                                </div>
                              </div>

                            }

                          <div className="jeopardy-button-container titan-one-small">
                            {showAnswer?
                                <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Question</button>
                              :
                                <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Answer</button>
                            }
                          </div>

                        </div>
                    : 
                    <div className="d-flex flex-column justify-content-center align-items-center h-100 nunito">
                      <h1>No Question Made</h1>
                    </div>
                    }
                           
                    </div> 
                </div>
                :""
                }        
                {/* modal end */}
                <div className="controls-container">
                    <p onClick={handleFullscreen} className="full-screen-button z-3">
                        {fullscreen ? <i className="fa-solid fa-minimize"/> : <i className="fa-solid fa-maximize"/>}
                    </p>
                    <p onClick={toggleMute} className="mute-button z-3">
                        {isMuted || volume === 0 ? <i className="fa-solid fa-volume-mute" style={{color:'rgb(69 0 117)'}}/> : <i className="fa-solid fa-volume-high"/>}
                    </p>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={isMuted ? 0 : volume}
                        onChange={changeVolume}
                        style={{ marginLeft: '10px' }}
                        className="volume-slider z-3"
                        aria-label="Volume"
                    />
                </div>
                    {/* GAME BOARD */}
                <div className='game-body'> 
                      <div className={"grid-container grid-container-"+ totalColumns}>

                          {[...Array(totalColumns).keys()].map( columnKey => {
                              let columnID = ["a","b","c","d","e","f"];
                              columnKey = columnKey+1;
                              return <div key={columnKey}>

                                      {/*~~~~~~~~~~~~~~~~~~~~~~~ set category names~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                                  <div className={"grid-category jeopardy-col-"+columnKey} style={{height:60/totalRows+"vh"}} >
                                      <p>{categoryNames[columnKey] ? categoryNames[columnKey] : ""}</p>
                                  </div>


                                      {/*~~~~~~~~~~~~~~~~~~~~~~~ set questions and answers~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

                                  {[...Array(totalRows).keys()].map( rowKey => {
                                      rowKey=rowKey+1
                                      let position = columnID[columnKey-1]+rowKey;
                                      return <div key={rowKey} className={"grid-item jeopardy-col-"+columnKey+ (modalOpened[position]? '-opened':'')+ " a1-modal"} style={{height:60/totalRows+"vh"}} onClick={(e) => openModal(e,columnID[columnKey-1]+rowKey)} >
                                              <p>{points[rowKey]}</p>
                                          
                                          </div>
                                  }
                                  )}
                              </div>

                          }
                          )}
                      </div>
                      <Container fluid>
                        <Row className="justify-content-center">
                          {Object.keys(teams).map(key => {
                                    
                                    return  <Col xs='2'>

                                      <PlayerCard
                                      name={teams[key].name}
                                      src={teams[key].image}
                                      points={teams[key].points}
                                      onAddPointsClick={e=>addPoints(key)}
                                      onMinusPointsClick={e=>minusPoints(key)}
                                      className={teamBuzzed===key? "player-card-buzzed":""}
                                      questionSelected={modalToggle}
                                      onCorrectClick={e=>onCorrectClick(key)}
                                      onIncorrectClick={e=>onIncorrectClick(key)}
                                      >
                                      </PlayerCard>
                                      </Col>

                                }
                          )}
                        </Row>
                      </Container>
                  </div>
            </div>
          }
        </div>
        )
    
}

export default Jeopardy;